import React from 'react';
import { Container, Typography } from '@itsa.io/ui';
import { useIntl } from '@itsa.io/web3utils';

const NotFoundPage = () => {
	const { t } = useIntl();

	return (
		<Container maxWidth="xs" style={{ marginTop: '1rem' }}>
			<Typography variant="body1" align="center">
				{t('page.404.heading')}
			</Typography>
		</Container>
	);
};

export default NotFoundPage;
